export async function useLivestory(contentType: string, contentId: string) {
  const { language } = useRouteHelper()

  const query = {
    contentType: contentType,
    contentId: contentId,
    lang: language,
  }
  return await useFetch('/api/livestory', {
    query,
  })
}
