export const useLiveStoryScript = (parentEl: Ref<HTMLElement | null>) => {
  useScript(
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js',
      tagPosition: 'head',
      async: true,
    },
    {
      trigger: 'server',
    }
  )
  return useScript(
    {
      type: 'text/javascript',
      async: true,
      src: 'https://assets.livestory.io/dist/livestory-armani.min.js',
      key: 'LiveStoryArmaniScript',
    },
    {
      bundle: false,
      trigger: 'server',
    }
  )
}
